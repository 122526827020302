<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$UDAF')
                  ? `/dashboard-udaf`
                  : `/proyectos/cns/estimacion-presupuesto/admin-dash`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark">
              Estimación anual del presupuesto </span
            ><br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ nombreInstitucion }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
              :disabled="skeletonLoading || accionesBloqueadas"
            >
              Registrar Estimación
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="estimaciones"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Año, periodo"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.periodo }}</td>
              <td>
                {{ item.codigoSicoopera }}
              </td>
              <td>{{ item.nombreProyecto }}</td>
              <td>{{ item.fuenteCooperante }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 3
                      ? 'teal lighten-4'
                      : item.estadosId === 4
                      ? 'light-blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 3
                      ? 'teal lighten-1'
                      : item.estadosId === 4
                      ? 'light-blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{
                    item.estadosId === 1
                      ? `En registro`
                      : item.estadosId === 3
                      ? `Confirmado`
                      : item.estado
                  }}
                </v-chip>

                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 3) &&
                      item.procedencia == `SICOOPERA`
                  "
                  color="deep-orange lighten-2"
                  class="ml-1"
                  @click="
                    mostrarDialogAdvertenciaEstado(
                      item.estadosId === 1
                        ? `Es necesario generar y confirmar el IAFF correspondiente al ${item.nombreBimestre} bimestre del año ${item.periodo}.`
                        : `Es necesario confirmar el IAFF correspondiente al ${item.nombreBimestre} bimestre del año ${item.periodo}.`
                    )
                  "
                  text
                  fab
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="(item.estadosId === 1 || item.estadosId === 4)"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogActualizar(item.id)"
                >
                  <v-icon left>{{item.estadosId === 1 ? `mdi-pencil` : `mdi-eye-outline`}}</v-icon
                  >{{
                    item.estadosId == 1
                      ? `Actualizar información`
                      : item.estadosId === 4
                      ? `Visualizar`
                      : ``
                  }}
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 44"
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="
                    item.procedencia == 'SIGEACI'
                      ? descargarArchivo(item.pathIaff, 'sigeaci')
                      : descargarArchivo(item.pathIaff, 'sicoopera')
                  "
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar estimación
                </v-btn>
                <v-btn
                  v-if="
                  (currentRoles.includes('SCO$ADMINISTRADOR') ||
                      currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="
                    mostrarModalActivar(
                      item.id, item.nombreProyecto, item.estadosId == 1 || item.estadosId == 4 ? 2 : item.estadosId == 2 ? 1 : 0
                    )
                  "
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>{{item.estadosId == 1 || item.estadosId == 4 ? `mdi-minus` : `mdi-plus`}}</v-icon>
                  {{item.estadosId == 1 || item.estadosId == 4 ? `Deshabilitar` : `Habilitar`}}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--inicio:: dialog registro -->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1200"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ dialogTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarEstimacion"
            v-model="validForm"
          >
            <v-row class="mt-2" v-if="tipoEstimacion == 1">
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                <v-select
                  v-model="datosEstimacion.proyectosCNSId"
                  :items="proyectos"
                  class="required"
                  dense
                  outlined
                  :no-data-text="
                    proyectos != null
                      ? 'Seleccione el proyecto'
                      : 'No se han encontrado proyectos'
                  "
                  label="Seleccionar proyecto"
                  :item-text="item => item.codigoSicoopera + ` | ` + item.nombreProyecto"
                  item-value="id"
                  :rules="[
                    tipoEstimacion == 1 ? selectRequired('proyecto') : true
                  ]"
                  @change="obtenerDatosProyecto(datosEstimacion.proyectosCNSId)"
                >
                  <!-- <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="toggle"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="rolesSeleccionados.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Seleccionar todos
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template> -->
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="skeletonInfoProyectoLoading">
              <v-col cols="12" md="12">
                <v-skeleton-loader
                  v-if="skeletonInfoProyectoLoading"
                  type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, actions"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <div v-if="(!skeletonInfoProyectoLoading && datosEstimacion.proyectosCNSId && datosEstimacion.proyectosCNSId != 0) || tipoEstimacion == 2 ">
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-textarea
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosEstimacion.nombreProyecto"
                    :rules="[required('Nombre del proyecto')]"
                    label="Nombre del proyecto"
                    maxlength="2000"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                  <v-select
                    v-model="datosEstimacion.tiposFuenteCooperanteId"
                    :items="tiposFuenteCooperante"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    label="Tipo"
                    item-text="nombreTipoFuente"
                    item-value="id"
                    :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                    :no-data-text="
                      tiposFuenteCooperante != null
                        ? 'Selecciona un tipo de fuente cooperante'
                        : 'No se han encontrado tipos de fuente cooperante'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="datosEstimacion.tiposFuenteCooperanteId == 1">
                  <v-select
                    v-model="datosEstimacion.fuentesBilateralesId"
                    :items="fuentesBilaterales"
                    :loading="false"
                    :required="datosEstimacion.tiposFuenteCooperanteId == 1"
                    dense
                    :class="datosEstimacion.tiposFuenteCooperanteId == 1 ? 'required' : ''"
                    filled
                    label="País Fuente Bilateral"
                    item-text="nombre"
                    item-value="id"
                    :rules="[
                      datosEstimacion.tiposFuenteCooperanteId === 1 ? selectRequired('país bilateral') : true,
                    ]"
                    :no-data-text="
                      fuentesBilaterales != null
                        ? 'Selecciona una fuente bilateral'
                        : 'No se han encontrado fuentes bilaterales'
                    "
                    @change="obtenerAgenciasPorFuenteBilateral(datosEstimacion.fuentesBilateralesId)"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>

                <!-- inicio:: agencias -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="datosEstimacion.tiposFuenteCooperanteId == 1">
                  <v-select
                    v-model="datosEstimacion.agenciasId"
                    :items="agencias"
                    :loading="ddAgenciasLoading"
                    dense
                    filled
                    label="Seleccione Agencia"
                    item-text="nombreAgencia"
                    item-value="id"
                    :no-data-text="
                      agencias > 0
                        ? 'Selecciona una agencia'
                        : 'No se han encontrado agencias'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
                <!-- fin:: agencias -->

                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="datosEstimacion.tiposFuenteCooperanteId == 2">
                  <v-select
                    v-model="datosEstimacion.fuentesMultilateralesId"
                    :items="fuentesMultilateral"
                    :loading="false"
                    dense
                    filled
                    label="Seleccione fuente multilateral"
                    item-text="nombreFuenteMultilateral"
                    item-value="id"
                    :no-data-text="
                      fuentesMultilateral != null
                        ? 'Seleccione una fuente multilateral'
                        : 'No se han encontrado fuentes multilaterales'
                    "
                    menu-props="auto"
                    @change="obtenerAgenciasMultilaterales(datosEstimacion.fuentesMultilateralesId)"
                  ></v-select>
                </v-col>

                <!-- inicio:: agencias -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="datosEstimacion.tiposFuenteCooperanteId == 2">
                  <v-select
                    v-model="datosEstimacion.agenciaMultilateralId"
                    :items="agenciasMultilaterales"
                    :loading="ddAgenciasMultiLoading"
                    dense
                    filled
                    label="Seleccione Agencia Multilateral"
                    item-text="agencia"
                    item-value="id"
                    :no-data-text="
                      agenciasMultilaterales > 0
                        ? 'Selecciona una agencia'
                        : 'No se han encontrado agencias para la fuente seleccionada'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
                <!-- fin:: agencias -->
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosEstimacion.fuenteEspecifica"
                    :rules="[required('Fuente específica')]"
                    label="Fuente específica"
                    maxlength="250"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    :class="tipoEstimacion == 1 ? `required` : ``"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosEstimacion.siglasProyecto"
                    :rules="
                      tipoEstimacion == 1
                        ? [required('Siglas del proyecto')]
                        : true
                    "
                    label="Siglas del proyecto"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosEstimacion.nombreInstitucion"
                    :rules="[required('Entidad')]"
                    label="Entidad"
                    maxlength="500"
                    :disabled="true"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="datosEstimacion.ENTIDAD_ID"
                    :items="instituciones"
                    :loading="ddInstitucionesLoading"
                    dense
                    filled
                    class="required"
                    label="Institución"
                    :item-text="
                      item =>
                        item.sigla
                          ? `${item.nombreInstitucion} (${item.sigla})`
                          : item.nombreInstitucion
                    "
                    item-value="id"
                    :no-data-text="
                      instituciones != null
                        ? 'Selecciona una institución'
                        : 'No se han encontrado instituciones'
                    "
                    :disabled="
                      !currentRoles.includes('SCO$ADMINISTRADOR') ||
                        !currentRoles.includes('SCO$SUBADMINISTRADOR')
                    "
                    @change="
                      obtenerUnidadesEjecutorasPorInstitucion(
                        datosEstimacion.ENTIDAD_ID
                      )
                    "
                    menu-props="auto"
                    :rules="[selectRequired('institución')]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
                  <v-select
                    v-model="unidadEjecutoraSeleccionada"
                    :items="unidadesEjecutoras"
                    :loading="unidadesEjecutorasLoading"
                    dense
                    filled
                    class="required"
                    label="Unidad Ejecutora"
                    item-text="unidadEjecutora"
                    item-value="id"
                    return-object
                    :rules="[selectRequired('unidad ejecutora')]"
                    :no-data-text="
                      unidadesEjecutoras.length > 0
                        ? 'Selecciona una unidad ejecutora'
                        : 'No se han encontrado unidades ejecutoras'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
                  <v-select
                    v-model="datosEstimacion.situacionDonacion"
                    :items="situacionDonacionItems"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    label="Situación de la donación"
                    item-text="text"
                    item-value="id"
                    :rules="[selectRequired('situación de la donación')]"
                    :no-data-text="
                      unidadesEjecutoras.length > 0
                        ? 'Seleccione la situación de la donación'
                        : 'No se han encontrado situación de la donación'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    :class="tipoEstimacion == 1 ? `required` : ``"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="datosEstimacion.numeroAcuerdo"
                    :rules="
                      tipoEstimacion == 1
                        ? [required('número de acuerdo')]
                        : true
                    "
                    label="Número de acuerdo"
                    maxlength="25"
                    :disabled="
                      tipoEstimacion == 1 &&
                        (datosEstimacion.numeroAcuerdo === 'undefined' ||
                          datosEstimacion.numeroAcuerdo === null)
                    "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    autocomplete="off"
                    class=""
                    dense
                    filled
                    color="blue-grey lighten-2"
                    suffix="%"
                    v-model="porcentajeFuncionamiento"
                    label="¿Permite financiar funcionamiento? Porcentaje"
                    :rules="[decimals('Avance'), maxNumber('Avance', 100)]"
                    maxlength="6"
                    hint="Sólo se permiten 2 decimales para este campo"
                    :disabled="false"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="12" sm="12" class="pt-1 pb-1">
                  <v-spacer></v-spacer>

                  <!--:disabled="!validDocForm" type="submit"-->
                  <v-btn
                    v-if="datosEstimacion.estadosId == 1"
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    :elevation="0"
                    :disabled="!validForm"
                    :loading="btnRegistroLoading"
                  >
                    {{ btnRegistroText }}
                  </v-btn>

                  <v-btn
                    v-if="accion == 1"
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-5 mr-1"
                    @click="
                      dialogRegistro = false;
                      resetForm();
                    "
                    :disabled="btnRegistroLoading"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <v-row class="mt-6" v-if="accion == 2">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-h5 black--text">
                Datos Complementarios
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>
          <v-form
            v-if="accion == 2 && datosEstimacion.estadosId == 1"
            ref="form"
            v-on:submit.prevent="registrarProyeccion"
            v-model="validFormPresupuesto"
          >
            <v-row>
              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                  v-model="datosProyeccion.periodosId"
                  :items="periodos"
                  :loading="ddPeriodosLoading"
                  dense
                  filled
                  label="Año de estimación"
                  item-text="periodo"
                  item-value="id"
                  :rules="[selectRequired('periodo / año')]"
                  :no-data-text="
                    periodos != null
                      ? 'Selecciona un periodo'
                      : 'No se han encontrado periodos'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
                <v-select
                  v-model="datosProyeccion.tipoFinanciamiento"
                  :items="financiamientoItems"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  label="Financiamiento"
                  item-text="text"
                  item-value="id"
                  :rules="[selectRequired('tipo de financiamiento')]"
                  :no-data-text="
                    financiamientoItems.length > 0
                      ? 'Seleccione un tipo de financiamiento'
                      : 'No se han encontrado tipos de financiamiento'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoMonedaOriginal"
                  :label="`Monto $`"
                  :rules="[
                    required('monto $'),
                    notComma('monto $'),
                    decimals10('monto $')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="tipoCambio"
                  label="Tipo de cambio"
                  :rules="[
                    required('tipo de cambio'),
                    decimals10('tipo de cambio')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  :disabled="true"
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  suffix="GTQ"
                  v-model="datosProyeccion.montoTotalQuetzales"
                  label="Monto total quetzales"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormPresupuesto"
                  :loading="btnRegistroProyeccionLoading"
                >
                  Agregar registro
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-4" v-if="accion == 2">
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersProyecciones"
                :items="proyecciones"
                :loading="tableProyeccionesLoading"
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                no-data-text="No se encontraron registros"
                noResultsText="No se encontraron registros"
                loading-text="Cargando..."
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.periodo }}</td>
                    <td>{{ item.tipoFinanciamiento === 1 ? `Recursos externos` : `Contrapartida` }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoDolares).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: maxDigits,
                        })
                      }}
                    </td>
                    <td class="text-right">{{ item.tipoCambio }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoTotalQuetzales).toLocaleString(
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                          maximumFractionDigits: maxDigits,
                        })
                      }}
                    </td>
                    <!-- <td class="text-right"> Q {{ parseFloat(item.montoTotalQuetzales) }}</td> -->
                    <td>
                      <v-btn
                        v-if="(!seccionesBloqueadas || currentRoles.includes('SCO$ADMINISTRADOR')) && datosEstimacion.estadosId == 1"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarProyeccion(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de proyecciones
                  </p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="accion == 2 && proyecciones.length > 0">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Confirmar estimación anual del presupuesto
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>
          <v-form
            v-if="accion == 2"
            ref="form"
            v-on:submit.prevent="mostrarDialogConfirmar"
            v-model="validFormConfirmacion"
          >
            <v-row v-if="datosEstimacion.estadosId == 1">
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormConfirmacion"
                  :loading="btnConfirmacionLoading"
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="text-center"
                v-if="datosEstimacion.estadosId === 4"
              >
                <v-icon large color="#26c6da">
                  mdi-check-all
                </v-icon>

                <p class="text-h6">
                  Estimación Anual Confirmada
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn
                v-if="accion == 2"
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-1"
                @click="
                  dialogRegistro = false;
                  resetForm();
                "
                :disabled="btnRegistroLoading"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--fin:: dialog registro-->

    <!--inicio:: dialog confirmacion-->
    <v-dialog
      v-model="dialogConfirmar"
      max-width="800"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          Confirmar Estimación

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnConfirmacionLoading"
            @click="dialogConfirmar = false;"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <p class="text-h6">Está seguro de confirmar la estimación anual del presupuesto para el proyecto {{ datosEstimacion.nombreProyecto }} del año {{ periodoEstimacion }}</p>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col md="12" sm="12" class="pt-1 pb-1">
              <v-spacer></v-spacer>

              <!--:disabled="!validDocForm" type="submit"-->
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="!validFormConfirmacion"
                :loading="btnConfirmacionLoading"
                @click="confirmarEstimacion"
              >
                Sí, continuar
              </v-btn>

              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-1"
                @click="dialogConfirmar = false"
                :disabled="btnConfirmacionLoading"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--fin:: dialog confirmacion-->

    <!--inicio:: dialog habilitar/deshabilitar-->
    <v-dialog
      v-model="dialogActivar"
      max-width="800"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{
            accionActivacion == 1
              ? `Habilitar estimación`
              : `Deshabilitar estimación`
          }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnConfirmacionLoading"
            @click="dialogActivar = false;"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <p class="text-h6">
            {{
              `¿Está seguro de ${
                accionActivacion == 1 ? `habilitar nuevamente` : `deshabilitar`
              } la estimación anual del presupuesto para el proyecto ${proyectoHabilitarDeshabilitar}`
            }}? <br />{{
              `${
                accionActivacion == 1
                  ? `Al habilitarla, se tomará en cuenta en los consolidados que se registren posteriormente.`
                  : `Si deshabilita esta estimación, ya no será tomada en cuenta para los consolidados que se registren posteriormente. `
              }`
            }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col md="12" sm="12" class="pt-1 pb-1">
              <v-spacer></v-spacer>

              <!--:disabled="!validDocForm" type="submit"-->
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="false"
                :loading="btnConfirmacionLoading"
                @click="activarEstimacion()"
              >
                Sí, continuar
              </v-btn>

              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-1"
                @click="dialogActivar = false"
                :disabled="btnConfirmacionLoading"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--fin:: dialog habilitar/deshabilitar-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//import DownloadFile from "@/core/untils/downloadfile.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

import {
  //CONFIRMAR_PROYECTO_CNS,
  OBTENER_DATOS_SECCIONES_PROYECTO,
  OBTENER_PROYECTOS_CNS_ENTIDAD
} from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import { 
  OBTENER_ESTIMACIONES_ENTIDAD,
  OBTENER_ESTIMACION_ANUAL_CNS,
  REGISTRAR_ESTIMACION_ANUAL_CNS,
  ACTUALIZAR_ESTIMACION_ANUAL_CNS,
  CONFIRMAR_ESTIMACION_ANUAL_CNS,
  ACTUALIZAR_ESTADO_ESTIMACION_ANUAL_CNS
} from "@/core/services/store/proyectoscns/estimacionanualpresupuesto/estimacionanualpresupuesto.module.js";

import {
  REGISTRAR_PROYECCION_ESTIMACION_CNS,
  OBTENER_PROYECCIONES_ESTIMACION_CNS,
  ELIMINAR_PROYECCION_ESTIMACION_CNS
} from "@/core/services/store/proyectoscns/estimacionanualpresupuesto/proyeccionesestimacionanualcns.module.js";
export default {
  name: "AdminEstimacionAnualPresupuesto",
  data() {
    return {
      filtro: "",
      tableLoading: false,
      skeletonLoading: false,
      accionActivacion: 1,
      proyectoHabilitarDeshabilitar: "",
      periodoActual: {},
      periodoSiguiente: {},
      datosProyeccion: {
        periodosId: 0,
      },
      dialogActivar: false,
      idEstimacionActivar: 0,
      maxDigits: 2,
      dialogConfirmar: false,
      nombreInstitucion: "",
      seccionesBloqueadas: false,
      skeletonInfoProyectoLoading: false,
      dialogRegistro: false,
      btnRegistroLoading: false,
      ddProyectosLoading: false,
      proyectos: [],
      datosEstimacion: {},
      ddAgenciasLoading: false,
      agencias: [],
      periodoEstimacion: "",
      agenciasMultilaterales: [],
      ddAgenciasMultiLoading: false,
      instituciones: [],
      ddInstitucionesLoading: false,
      unidadesEjecutoras: [],
      unidadEjecutoraSeleccionada: {},
      porcentajeFuncionamiento: "",
      unidadesEjecutorasLoading: false,
      dialogTitle: "",
      validForm: false,
      validFormPresupuesto: false,
      validFormConfirmacion: false,
      btnConfirmacionLoading: false,
      datosConfirmacion: {},
      btnRegistroProyeccionLoading: false,
      btnRegistroText: "Registrar estimación",
      tiposFuenteCooperante: [],
      accion: 1,
      proyectoId: 0,
      codigoProyecto: "",
      btnEliminarDisabled: false,
      datosProyecto: {},
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      tipoEstimacion: 1, //1 con codigo sicoopera, 2 sin codigo sicoopera
      entidadId: 0,
      periodos: [],
      ddPeriodosLoading: false,
      estimaciones: [],
      montoMonedaOriginal: "",
      tipoCambio: "",
      tableProyeccionesLoading: false,
      proyecciones: [],
      situacionDonacionItems: [
        { id: 1, text: "Gestión" },
        { id: 2, text: "Ejecución" }
      ],
      estadoProyecto: {},
      financiamientoItems: [
        { id: 1, text: "Recursos Externos" },
        { id: 2, text: "Contrapartida" }
      ],
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "En registro" },
        { value: 2, text: "Inactivo" },
        { value: 4, text: "Confirmado" }
      ],
      accionesBloqueadas: false,
      datosInstitucion: {},
      ...validations
    };
  },
  components: {
    DialogLoader,
    SnackAlert,
    DivisorColor
  },
  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.value;
    },

    resetForm() {
      this.accion = 1;
      this.porcentajeFuncionamiento = "";
      this.btnRegistroText = "Registrar estimación";
      this.datosEstimacion = {};
      this.datosEstimacion.ENTIDAD_ID = this.currentUser.institucionId;
      this.obtenerUnidadesEjecutorasPorInstitucion(this.datosEstimacion.ENTIDAD_ID);
    },
    async obtenerEstimaciones() {
      this.tableLoading = true;
      this.datosItem = {};

      await this.$store
        .dispatch(OBTENER_ESTIMACIONES_ENTIDAD, {
          entidadId: this.entidadId,
          tipoEstimacion: this.tipoEstimacion,
          estadoId:
            this.currentRoles.includes("SCO$ADMINISTRADOR") ||
            this.currentRoles.includes("SCO$SUBADMINISTRADOR")
              ? 0
              : 1
        })
        .then(res => {
          if (res.status === 200) {
            this.estimaciones = res.data;
            /*this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            //this.obtenerEstados();*/
          }
          this.tableLoading = false;
        })
        .catch(() => {
          //console.log(er)
          this.tableLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    modalNuevo() {
      this.dialogTitle = "Registrar estimación " + new Date().getFullYear();
      this.proyecciones = [];
      this.accion = 1;
      this.datosEstimacion.estadosId = 1;
      this.datosEstimacion.ENTIDAD_ID = this.currentUser.institucionId;
      this.obtenerUnidadesEjecutorasPorInstitucion(this.datosEstimacion.ENTIDAD_ID);
      this.dialogRegistro = true;
    },

    async obtenerProyectos() {
      this.ddProyectosLoading = true;
      this.proyectos = [];

      await this.$store
        .dispatch(OBTENER_PROYECTOS_CNS_ENTIDAD, {
          entidadId: this.entidadId,
          tipoCooperacionId: 1 //Cooperacion financiera
        })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
            // console.log(this.datosItem)
            /*this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            //this.obtenerEstados();*/
          }
          this.ddProyectosLoading = false;
        })
        .catch(() => {
          this.ddProyectosLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    async obtenerAgenciasPorFuenteBilateral(fuenteBilateralId) {
      this.agencias = [];
      this.ddAgenciasLoading=true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: fuenteBilateralId})
        .then(res => {
          if (res.status === 200) {
            this.agencias = res.data;
          }
          this.ddAgenciasLoading=false;
        })
        .catch(() => {
          this.ddAgenciasLoading=false;
          this.agencias = [];
        });
    },

    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {estadoId:1, fuentesMultilateralesId: fuenteMultilateralId})
        .then(res => {
          if (res.status===200) {
            this.agenciasMultilaterales = res.data;
          }
          this.ddAgenciasMultiLoading = false;
        })
        .catch(() => {
          this.ddAgenciasMultiLoading = false;
        });
    },

    /// Obtener los items
    async obtenerInstituciones() {
      //this.datosItem.unidadEjecutoraId =0;
      this.ddInstitucionesLoading = true;

      this.instituciones = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
        .then(res => {
          if (res.status == 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          this.ddInstitucionesLoading = false;
          this.instituciones = [];
        });
    },

    //Obtener tipos de datos financieros
    async obtenerPeriodos() {
      this.periodos = [];

      this.ddPeriodosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
          this.ddPeriodosLoading = false;
        })
        .catch(() => {
          this.periodos = [];
          this.ddPeriodosLoading = false;
        });
    },

    async obtenerUnidadesEjecutorasPorInstitucion(institucionesId) {
      // this.datosItem.unidadEjecutoraId=0;
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, institucionesId)
        .then(res => {
          if (res.status === 200) {
            this.unidadesEjecutoras = res.data;

          }
          this.unidadesEjecutorasLoading = false;
        })
        .catch(()=> {
          this.unidadesEjecutorasLoading = false;
        });
    },

    async obtenerDatosProyecto(proyectoId) {
      this.skeletonInfoProyectoLoading = true;
      this.datosItem = {};

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;
            this.datosEstimacion.nombreProyecto = this.datosProyecto.nombreProyecto;
            this.datosEstimacion.tiposFuenteCooperanteId = this.datosProyecto.tiposFuenteCooperanteId;
            this.datosEstimacion.fuentesBilateralesId = this.datosProyecto.fuentesBilateralesId;
            this.datosEstimacion.fuentesMultilateralesId = this.datosProyecto.fuentesMultilateralesId;
            this.datosEstimacion.ENTIDAD_ID = this.datosProyecto.institucionesId;
            this.datosEstimacion.nombreInstitucion = this.datosProyecto.nombreInstitucion;

            if (this.datosProyecto.institucionesId) {
              this.obtenerUnidadesEjecutorasPorInstitucion(this.datosProyecto.institucionesId).then(()=> {
                if (this.datosProyecto.unidadEjecutoraId && this.datosProyecto.unidadEjecutoraId != 0) {
                  this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => {
                    if(item.id === this.datosProyecto.unidadEjecutoraId && item.entidadId === this.datosProyecto.institucionesId) return true;
                  });
                }
              });
            }
            this.datosProyecto.numeroAcuerdo
              ? (this.datosEstimacion.numeroAcuerdo = this.datosProyecto.numeroAcuerdo)
              : (this.datosEstimacion.numeroAcuerdo = "");

            // console.log(this.datosItem)
            /*this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            //this.obtenerEstados();*/
          }
          this.skeletonInfoProyectoLoading = false;
        })
        .catch(() => {
          this.skeletonInfoProyectoLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async mostrarDialogActualizar(id) {
      this.dialogLoaderText = "Obteniendo información de la estimación.";
      this.dialogLoaderVisible = true;
      await this.$store
        .dispatch(OBTENER_ESTIMACION_ANUAL_CNS, {
          id: id
        })
        .then(res => {
          if (res.status === 200) {
            this.btnRegistroText = "Actualizar estimación";
            this.datosEstimacion = res.data;
            if (this.tipoEstimacion == "1") {
              this.datosEstimacion.proyectosCNSId = this.datosEstimacion.proyectosCNSId;
            } else {
              this.datosEstimacion.proyectosCNSId = null;
            }
            this.datosEstimacion.nombreProyecto = this.datosEstimacion.nombreProyecto;
            this.datosEstimacion.tiposFuenteCooperanteId = this.datosEstimacion.tiposFuenteCooperanteId;
            this.datosEstimacion.fuentesBilateralesId = this.datosEstimacion.fuentesBilateralesId;
            this.datosEstimacion.fuentesMultilateralesId = this.datosEstimacion.fuentesMultilateralesId;
            this.datosEstimacion.ENTIDAD_ID = this.datosEstimacion.institucionesId;
            this.datosEstimacion.nombreInstitucion = this.datosEstimacion.nombreInstitucion;
            this.datosEstimacion.numeroAcuerdo = this.datosEstimacion.numeroAcuerdo;
            this.datosEstimacion.porcentajeFuncionamiento ? this.porcentajeFuncionamiento = this.datosEstimacion.porcentajeFuncionamiento.toString() :  this.porcentajeFuncionamiento = "";
            this.datosEstimacion.situacionDonacion = this.datosEstimacion.situacionDonacion;
            if (this.datosEstimacion.institucionesId) {
              this.obtenerUnidadesEjecutorasPorInstitucion(this.datosEstimacion.institucionesId).then(()=> {
                if (this.datosEstimacion.unidadEjecutoraId && this.datosEstimacion.unidadEjecutoraId != 0) {
                  this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => {
                    if(item.id === this.datosEstimacion.unidadEjecutoraId && item.entidadId === this.datosEstimacion.institucionesId) return true;
                  });
                }
              });
            }
            this.datosEstimacion.numeroAcuerdo
              ? (this.datosEstimacion.numeroAcuerdo = this.datosEstimacion.numeroAcuerdo)
              : (this.datosEstimacion.numeroAcuerdo = "");
            this.datosConfirmacion.nombreDirectorAdminFinanciera = this.datosEstimacion.nombreDirectorAdminFinanciera;
            this.datosConfirmacion.nombreRegistroInforme = this.datosEstimacion.nombreRegistroInforme;
            this.dialogTitle = "Actualizar estimación " + this.datosEstimacion.periodo;
            this.obtenerProyeccionesEstimacion(this.datosEstimacion.id);
            // console.log(this.datosItem)
            /*this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            //this.obtenerEstados();*/
            this.accion = 2;
            this.dialogRegistro = true;
          }
          this.dialogLoaderVisible = false;
        })
        .catch(e => {
          console.log(e)
          this.dialogLoaderVisible = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async registrarEstimacion() {
      this.datosEstimacion.UNIDAD_EJECUTORA_ID = this.unidadEjecutoraSeleccionada.id;
      this.datosEstimacion.porcentajeFuncionamiento = parseFloat(
        this.porcentajeFuncionamiento);
      if (this.datosEstimacion.tiposFuenteCooperanteId == 1) {
        this.datosEstimacion.fuentesMultilateralesId = null;
        this.datosEstimacion.agenciaMultilateralId = null;
      } else if (this.datosEstimacion.tiposFuenteCooperanteId == 2) {
        this.datosEstimacion.fuentesBilateralesId = null;
        this.datosEstimacion.agenciasId = null;
      } else {
        this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Debe seleccionar un tipo de fuente cooperante.");
        return;
      }

      this.datosEstimacion.periodosId = this.periodoActual.id;
      this.porcentajeFuncionamiento != null
        ? (this.datosEstimacion.permiteFinanciarFuncionamiento = 1)
        : (this.datosEstimacion.permiteFinanciarFuncionamiento = 2);

      if (this.tipoEstimacion === "1") {
        this.datosEstimacion.cuentaCodigoSicoopera = 1;
        //Validar que si es estimación con código sicoopera, cuente con el proyectosCNSId
        if (
          !this.datosEstimacion.proyectosCNSId ||
          this.datosEstimacion.proyectosCNSId == 0
        ) {
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", "No se ha seleccionado un proyecto para la estimación");
          return;
        }
      } else {
        this.datosEstimacion.cuentaCodigoSicoopera = 2;
        this.datosEstimacion.proyectosCNSId = null;
      }
      // console.log(this.datosEstimacion)

      this.datosEstimacion.usuarioCreacion = this.currentUser.usuario;
      this.datosEstimacion.estadosId = 1;
      this.btnRegistroLoading = true;
      let dispatch = REGISTRAR_ESTIMACION_ANUAL_CNS;
      if (this.accion == 2) {
        dispatch = ACTUALIZAR_ESTIMACION_ANUAL_CNS;
      }

      await this.$store
        .dispatch(dispatch, { datos: this.datosEstimacion })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosEstimacion.id = res.data;
            //this.switchItemEstado = true;
            this.archivoCargado = false;
            this.accion = 2;
            this.btnRegistroText = "Actualizar estimación";
            this.obtenerEstimaciones();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "A ocurrido un error interno, por favor, póngase en contacto con un administrador del sistema " +
              error
          );
          this.btnRegistroLoading = false;
          this.btnInformacionAdicionalLoading = false;
        });
    },

    async obtenerProyeccionesEstimacion(estimacionId) {
      this.tableProyeccionesLoading = true;
      this.proyecciones = [];

      await this.$store
        .dispatch(OBTENER_PROYECCIONES_ESTIMACION_CNS, {
          estimacionId: estimacionId
        })
        .then(res => {
          if (res.status === 200) {
            this.proyecciones = res.data;
          }
          this.tableProyeccionesLoading = false;
        })
        .catch(()=> {
          this.tableProyeccionesLoading = false;
        });
    },

    async registrarProyeccion() {
      if (!this.datosEstimacion.id) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "No se ha seleccionado una estimación."
        );
        return;
      }
      this.btnRegistroProyeccionLoading = true;
      this.datosProyeccion.estimacionAnualCNSId = this.datosEstimacion.id;
      this.datosProyeccion.montoDolares = this.montoMonedaOriginal;
      this.datosProyeccion.tipoCambio = this.tipoCambio;
      this.datosProyeccion.usuarioCreacion = this.currentUser.usuario;
      this.datosProyeccion.estadosId = 1;
      console.log(this.datosProyeccion)
      await this.$store
        .dispatch(REGISTRAR_PROYECCION_ESTIMACION_CNS, {
          datos: this.datosProyeccion
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.switchItemEstado = true;
            this.obtenerProyeccionesEstimacion(this.datosEstimacion.id);
            this.datosProyeccion = {
              estimacionAnualCNSId: this.datosEstimacion.id,
              montoDolares: 0,
              montoTotalQuetzales: 0,
              tipoCambio: 0,
              tipoFinanciamiento: null
            };
            this.montoMonedaOriginal = "";
            this.tipoCambio = "";
            this.datosProyeccion.periodosId = this.periodoSiguiente.id;
            this.accion = 2;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProyeccionLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "A ocurrido un error interno, por favor, póngase en contacto con un administrador del sistema " +
              error
          );
          this.btnRegistroProyeccionLoading = false;
        });
    },

    mostrarDialogConfirmar() {
      this.dialogConfirmar = true;
    },

    async confirmarEstimacion() {
      this.dialogLoaderText = "Confirmando la estimación anual";
      this.dialogLoaderVisible = true;
      this.btnConfirmacionLoading = true;
      this.datosConfirmacion.id = this.datosEstimacion.id;
      await this.$store
        .dispatch(CONFIRMAR_ESTIMACION_ANUAL_CNS, this.datosConfirmacion)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosEstimacion.estadosId = 4;
            this.dialogConfirmar = false;
            //this.obtenerProyeccionesEstimacion(this.datosEstimacion.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.obtenerEstimaciones();
          this.btnConfirmacionLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnConfirmacionLoading = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async eliminarProyeccion(id) {
      //Validar que no existan distribuciones registradas
      this.dialogLoaderText = "Eliminando proyección";
      this.dialogLoaderVisible = true;
      this.btnEliminarDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_PROYECCION_ESTIMACION_CNS, {id})
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProyeccionesEstimacion(this.datosEstimacion.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      //Deshabilitar tipos de datos financieros para distribución
      //this.tiposDatosFinancierosDistribucion.find( item => item.id === id).disabled = true;
    },

    //Obtener datos institucion
    async obtenerDatosInstitucion(institucionId) {
      this.datosInstitucion = {};
      //this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/${institucionId}` })
        .then(res => {
          if (res.status === 200) {
            this.datosInstitucion = res.data;
            this.nombreInstitucion = this.datosInstitucion.nombreInstitucion;
          }
        })
        .catch(() => {
          this.datosInstitucion = {};
        });
    },

    //Tipo 1 Activar, Tipo 2 Desactivar
    mostrarModalActivar(id, nombreProyecto, tipo) {
      //console.log(id + " " + nombreProyecto + " " + tipo)
      this.accionActivacion = tipo;
      this.proyectoHabilitarDeshabilitar = nombreProyecto;
      this.idEstimacionActivar = id;
      this.dialogActivar = true;
    },

    async activarEstimacion() {
      this.btnConfirmacionLoading = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Actualizando estado de la estimación";
      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_ESTIMACION_ANUAL_CNS, {
          id: this.idEstimacionActivar,
          estadoId: this.accionActivacion
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.datosEstimacion.estadosId = 4;
            this.dialogActivar = false;
            //this.obtenerProyeccionesEstimacion(this.datosEstimacion.id);
            // this.resetItems();
            this.obtenerEstimaciones();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnConfirmacionLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnConfirmacionLoading = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    }
  },

  created() {
    this.parameters = this.$route.params;
    /*this.proyectoId = parseInt(
      this.$CryptoJS.AES.decrypt(
        this.parameters.proyectoId,
        "KEYADMINCNS2022"
      ).toString(this.CryptoJS.enc.Utf8)
    );
    this.obtenerDatosProyecto(this.proyectoId);
    */
    //this.codigoProyecto = this.parameters.codigoProyecto;
    this.tipoEstimacion = this.parameters.tipoEstimacion;
    this.entidadId = parseInt(this.parameters.entidadId);

    //Validar el usuario
    if (
      this.currentRoles.includes("SCO$ADMINISTRADOR") ||
      this.currentRoles.includes("SCO$SUBADMINISTRADOR")
    ) {
      this.obtenerProyectos();
      this.obtenerEstimaciones();
      this.accionesBloqueadas = false;
      this.datosEstimacion.ENTIDAD_ID = this.entidadId;
    }

    if (
      this.currentRoles.includes("SCO$UDAF") &&
      this.currentUser.institucionId == this.entidadId
    ) {
      this.obtenerProyectos();
      this.obtenerEstimaciones();
      this.accionesBloqueadas = false;
      this.datosEstimacion.ENTIDAD_ID = this.currentUser.institucionId;
    } else {
      /*this.$refs.snackalert.SnackbarShow(
        "warning",
        "Mensaje",
        "El usuario no coincide con la institución, por favor, inice sesión nuevamente."
      );*/
      this.accionesBloqueadas = true;
    }
    let anioActual = new Date().getFullYear();
    this.obtenerInstituciones();
    //console.log(this.currentUser);
    this.obtenerDatosInstitucion(this.datosEstimacion.ENTIDAD_ID);
    this.obtenerUnidadesEjecutorasPorInstitucion(this.datosEstimacion.ENTIDAD_ID);
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesBilaterales();
    this.obtenerFuentesMultilateral();
    this.obtenerPeriodos().then(() => {
      this.periodoActual = this.periodos.find(element => {
        if (element.periodo === anioActual.toString() ) {
          return true;
        }
      });

      this.periodoSiguiente = this.periodos.find(element => {
        if (parseInt(element.periodo) == anioActual + 1) {
          return true;
        }
      });

      this.datosProyeccion.periodosId = this.periodoSiguiente.id;
    });


    //this.obtenerPeriodos(this.proyectoId);
    //this.obtenerBimestres();
    //this.obtenerItems();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos" },
      { title: "CNS" },
      { title: "Estimación Anual Presupuesto" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }), //, currentRoles :"currentRoles"

    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "periodo"
        },
        {
          text: "Codigo",
          align: "start",
          sortable: true,
          value: "codigoSicoopera"
        },
        {
          text: "Nombre del Proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fuente Cooperante",
          align: "start",
          sortable: true,
          value: "fuenteCooperante"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadosId",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },
    headersProyecciones() {
      return [
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "periodo"
        },
        {
          text: "Financiamiento",
          align: "start",
          sortable: true,
          value: "financiamiento"
        },
        {
          text: "Monto $",
          align: "start",
          sortable: true,
          value: "montoDolares"
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "tipoCambio"
        },
        {
          text: "Monto Quetzales",
          align: "start",
          sortable: true,
          value: "montoTotalQuetzales",
          //filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  },
  watch: {
    montoMonedaOriginal: function() {
      this.datosProyeccion.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },

    tipoCambio: function() {
      this.datosProyeccion.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },
  }
};
</script>